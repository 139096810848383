import React from "react"
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <h4>
          <span>
            created by{" "}
            <a className="footer-link" href="https://www.kimyoonduk.com">
              yd kim
            </a>
          </span>
        </h4>
      </div>
    </footer>
  )
}

export default Footer
